import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const NikkeGuidesRumani: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Rumani guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_rumani.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Rumani Guide & Review</h1>
          <h2>A guide & review for Rumani in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>19/10/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Rumani" />
              <div className="employee-container for-nikke">
                <NikkeCharacter mode="icon" slug="rumani" enablePopover />
              </div>
              <p>
                Look at the move! Look at the skill! Rumani, what was that?
                Creating a gym named Body x Body on her own, Rumani is seeking
                to help anyone who wants to be stronger, bigger, better. But
                will she be able to help us commanders to pull her when the 2nd
                anniversary is so near?
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                In PvE, Rumani has no special standout skills that make her
                unique. She is a simple filler unit by every standard you may
                want to set. The best TL;DR for PvE is that she is a taunter
                with less effective self-healing, compared to Noise, for
                example. If not taken care of properly, she will die very often,
                especially when you leave her on AUTO, which always does fully
                charged shots. Against Bosses, the meta is to output the most
                damage possible while minimizing the usage of defensive Nikke(s)
                who do not provide extra firepower. In view of this, Rumani is
                hardly a competent candidate. While she does buff Normal
                Attacks, it is simply not enough to warrant her presence in the
                overall meta.
              </p>
              <p>
                The one redeeming quality of Rumani is her weapon. She wields
                Clip RL, a weapon that, to fully recharge its magazine, requires
                multiple iterations of “reload”. Each animation fills Rumani’s
                ammo 1/3rd of the way. The reason why this is important is
                because these types of RLs possess extremely high burst
                generation per hit. Notable and popular Clip RLs include Jackal,
                Centi, and Anis. If your team lacks burst generation, Rumani can
                definitely help that team get to the next burst faster so that
                CDR units are used more effectively. Even then, they are rarely
                needed in PvE settings anymore, as there are many better units
                now.
              </p>
              <p>
                Since Rumani possesses a high burst generation weapon, we can
                obviously talk about how good she will be… in PvP! Because we
                will be using PvP terms to explain this, we recommend going
                through this guide to get a hang of the PvP jargons commonly
                seen in our guides:
              </p>
              <Row
                xs={1}
                md={2}
                lg={2}
                xxl={2}
                className="g-3 category-cards-container"
              >
                <CategoryCard
                  title="PvP Arena Mechanics"
                  link="/nikke/guides/pvp-mechanics"
                  image={
                    <StaticImage
                      src="../../../images/nikke/categories/category_pvp2.jpg"
                      alt="PvP Arena Mechanics"
                    />
                  }
                />
              </Row>
              <p>
                Units with high burst generation thrive in PvP. Rumani is also
                only the 4th ever Clip RL to be released, so she is of high
                value to any PvP roster. If you have a full roster, it also
                opens up the possibility of having 2 easy to make 2RL speed
                teams, with the combos being Centi-Anis-Jackal, and
                Noah-Emilia-Rumani. This opens up to a new realm of super high
                speed combat in PvP, and may also help out in SP Arena Defense
                teams! With the advent of Champions Arena in PvP, Rumani might
                be considered an absolute must-have unit for any and all PvP
                lovers! Defender+Taunter+Clip RL? “Yes, please” for any PvP fan.
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                <strong>
                  If you do not care enough about PvP, Rumani is an easy skip.
                </strong>{' '}
                Her skills provide next to nothing for PvE, and hence her use is
                limited only to super incomplete rosters, be it in Campaign,
                Bossing or even Tribe Tower. If you really want her, Rumani will
                be available in the wishlist by next month, and you can test
                your luck there. With the incoming 2nd Anniversary event and the
                possibility of a super meta pilgrim unit dropping, it makes
                absolutely no sense to pull on Rumani, at all.
              </p>
              <p>
                At the same time, if you find yourself being a{' '}
                <strong>competitive PvP player</strong>, then Rumani is to be
                considered a <strong>must pull unit</strong>, especially with
                the announcement of Champions Arena being days away! You may
                even hunt for dupes if you are very very serious about competing
                for the top in Champions Arena. Otherwise, one copy should be
                fine since we can always hope for dupes through the wishlist.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <p>
                Rumani’s kit is extremely simple so we will try to keep it
                concise as compared to our previous guides.
              </p>
              <p>
                Rumani’s weapon is an RL which has partial reloading, known as a
                Clip RL. This allows her to have extremely high Burst
                Generation, which we have calculated to be 3.25% of the Burst
                Bar per hit, falling just short of Centi but staying above Noah
                in terms of how much they fill the bar.
              </p>
              <h5>Muscle Up</h5>
              <blockquote>
                <p>
                  ■ Activates when attacking with Full Charge. Affects self.
                </p>
                <p>
                  CMuscle Up: Max HP▲ 3.04%, stacks up to 5 time(s) and lasts
                  for 2 sec.
                </p>
                <p>
                  ■ Activates when hitting a target with Full Charge during Full
                  Burst Time. Affects the target.
                </p>
                <p>Taunt for 5 sec.</p>
              </blockquote>
              <p>
                The first part of her Skill 1 is a stackable Max HP buff called
                ‘Muscle Up’, which increases Rumani’s own Max HP on every full
                charged shot. It stacks 5 times, giving a total ~15% Max HP
                buff. The stacks can be purposely dropped if one wants to self
                heal with Rumani, similar to how Noise heals herself with each
                shot spaced ~2s apart with manual use. This skill is not as
                effective as Noise’s heal sadly, but is necessary to activate
                her Burst’s damage reduction buff. The requirement to activate
                the damage reduction in her Burst Skill is ‘Muscle Up’ needs to
                be fully stacked.
              </p>
              <p>
                The second part of Skill 1 is a simple taunt enemies on hit, but
                only if it was a fully charged shot during full burst.
                Particularly useful in PvP, especially when paired with Biscuit.
                It can work as a soft counter to S.Anis if Rumani manages to
                land a shot on her during Full Burst, since it would deviate her
                damage from DPS to herself.
              </p>
              <h5>Muscle Time</h5>
              <blockquote>
                <p>
                  ■ Activates when hitting a target's Parts for 5 time(s).
                  Affects all allies.
                </p>
                <p>Damage to Parts ▲ 10.05% for 5 sec.</p>
              </blockquote>
              <p>
                This… is pretty simple. Once Rumani hits a part 5 times, she
                will activate a Damage to Parts buff that she gives to all
                allies, not just herself. This is where the problem rises
              </p>
              <ul>
                <li>
                  If she only fully charges her shot, she won’t be able to
                  maintain this buff 100% of the time (5 shots will cost you
                  roughly 6.8s, not to mention reloading).
                </li>
                <li>
                  Spam tapping her shot will help extend the duration, in
                  exchange for losing S1’s buff.
                </li>
              </ul>
              <p>
                Well in the end, both skills are meh anyway in PVE, so you can
                forget them. In PVP, she’s forced to fully charge her shot so S2
                doesn’t matter beside cp padding.
              </p>
              <h5>Almighty Muscle</h5>
              <blockquote>
                <p>■ Affects self.</p>
                <p>Max HP ▲ 15.13% for 10 sec.</p>
                <p>■ Affects all allies.</p>
                <p>Normal Attack Damage Multiplier ▲ 10.05% for 10 sec.</p>
                <p>
                  ■ Activates when Muscle Up is fully stacked. Affects self.
                </p>
                <p>Damage Taken ▼ 20.06% for 10 sec.</p>
              </blockquote>
              <p>
                The first part of her burst buffs her own Max HP a bit more
                (considering she already does so with her Skill 1).
              </p>
              <p>
                The second part of her burst skill buffs Normal Attack Damage
                Multiplier for all allies. To explain how this works, this
                directly affects the Damage Multiplier. We can see that, in the
                full kit image shown at the start of the Kit Analysis section,
                for Rumani this Normal Attack Damage Multiplier is 56.38%. This
                would increase by 10.05% on Lvl 10, and give us a new value of
                62.05%, which will now become the new damage multiplier for
                Rumani’s normal attacks during full burst. This can be
                considered a good buff for shotgun allies due to their high
                Normal Attack Damage multiplier, and since they have prevalent
                use in PvP, they might just be able to enjoy this buff from
                Rumani to the fullest.
              </p>
              <p>
                The final part of this skill was already discussed above. When
                Rumani’s Skill 1’s ‘Muscle Up’ is fully stacked, it provides a
                damage reduction to herself, which increases her tanking
                ability. Paired with her Max HP buffs, this allows her to be an
                okayish tank. For PvP, this skill is meaningless since it is too
                slow to stack up.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Usage Analysis and Tier List" />
              <h5>
                Campaign (LD) - <strong className="a">A</strong> | Campaign (HD)
                - <strong className="b">B</strong>
              </h5>
              <p>
                Rumani can be effectively used as a burst generation battery and
                a taunter unit (think of Noise). However, taunt is a
                double-edged sword. If not taken care of properly, it will only
                be the cause of her imminent death. At the same time, it can be
                a useful tool for redirecting iframe-able attacks. While we do
                not expect Rumani to perform well in Campaign-like content and
                believe that she is overshadowed by many better support setups,
                she is still a good filler for beginners. Do note that she faces
                fierce competition in the B1 category.
              </p>
              <h5>
                Bossing - <strong className="b">B</strong>
              </h5>
              <p>
                Rumani might help you survive against some rare bosses, such as
                Rebuild Vulcan, or even Bowtie perhaps. Versus those bosses, In
                Vulcan you are required to sacrifice a unit so that the team can
                survive and deal more damage overall. In Bowtie, it is usually
                easier for gameplay to have damage focused on one unit and not
                spread out, so that all heals and cover heals may be focused on
                that unit since the boss deals minimal damage to cover but
                detrimental damage to HP bars of units. This niche might come
                into play in Tetra Tribe tower, for those who lack Noise.
                However, other than incomplete rosters making use of Rumani, we
                see no point in promoting her super niche use. In the overall
                meta, Rumani is pretty much useless to any veteran player. If
                you have a complete roster, be it Boss (Adds) or Boss (Solo),
                Rumani will not be the ideal unit to use.
              </p>
              <h5>
                PVP - <strong className="ss">SS</strong>
              </h5>
              <p>
                Well, all those days inside the gym weren’t for nothing. Rumani
                finds solace in PvP as her role is designed as a seriously high
                Burst Generation unit. The image below can show a proper
                comparison on how effective her burst generation is compared to
                other units. It shows the top burst generation units in PvP in
                order, ranked in ascending order for 2RL speed.
              </p>
              <StaticImage
                src="../../../images/nikke/reviews/rumani_guide_2.webp"
                alt="Kit"
              />
              <p>
                As you can see, Rumani comfortably sits in the Top 4 spot, with
                her only going down one spot due to Emilia’s “21% charge speed
                from S1+OL gear form” taking over her in later burst gen speeds.
                This just shows how strong of a choice she can be, not only for
                Champions Arena, but also for SP Arena, which is a constant
                income of gems for all players.
              </p>
              <p>
                Not just that, but Rumani is a defender unit that can taunt.
                Pair that with biscuit and you have a wall in your P1 position
                as long as the opponent team does not have proper AoE units or
                units that can bypass the wall: Emilia and S.Anis. However, even
                then, Rumani may be used as a soft counter to S.Anis if she can
                manage to hit her with a fully charged shot during full burst.
                This would deviate the damage that the opponent S.Anis was
                dealing to our DPS, and instead hit Rumani for at least 3-4
                rounds, allowing our DPS to deal free damage on the opponent
                team and have a soft edge on them. While PvP is usually all
                about hard counters, sometimes saving resources by properly
                using soft counters can immensely help team building for other
                rounds!
              </p>
              <p>
                All in all, the Burst generation is still the main reason to
                pull Rumani. It is very high, and can be thought of as a Centi
                2.0 in this case, but with a different element (Fire). Emilia
                might feast on her, but that’s only if she is able to hit her!
                Placing her in p4 and p5 is ideal because of this.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                As a Defender, gear and specific OL are not really important to
                her (because eventually, all gears will contribute too little
                compared to stat gain per level, and OL %Def sucks). You can OL
                her for A LOT OF CP padding if you like, for PvP of course. To
                Min-Max for PvP, we recommend the following:
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong>
                </li>
                <li>
                  <strong>Ideal:</strong> 4× Charge Speed Ammo
                </li>
                <li>
                  <strong>Passable:</strong> 4× ATK, 4× Elemental Damage, 4× DEF
                </li>
                <li>
                  <strong>Priority:</strong> Medium (PvP)
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <p>
                This will be very similar to how Bay’s Skill Investment page
                looked like.
              </p>
              <ul>
                <li>
                  <strong>Skill 1: 1-4</strong>
                </li>
                <ul>
                  <li>
                    Very small gain per skill upgrade. We only recommend
                    upgrading for CP padding.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 1-4</strong>
                </li>
                <ul>
                  <li>
                    This skill is completely useless for PvP, which is where
                    most of Rumani’s use lies. No point investing in this skill
                    further than 4 beside CP padding.
                  </li>
                </ul>
                <li>
                  <strong>Burst Skill: 1-4</strong>
                </li>
                <ul>
                  <li>
                    We hate to repeat the same sentence, but yeah, keep this
                    burst at max 4, even for CP Padding.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="rumani" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_7_sm.png"
                alt="Guide"
              />
              <p>
                Quantum Cube can effectively be used in some teams with Rumani
                due to her high Burst Generation. However, other PvP cubes like
                Tempering Cube, Vigor Cube, or Adjutant Cube may also be used to
                high effect.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: General Early Game Team</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="n102" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="dolla" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="placeholder-box">B3</div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rumani" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Rumani can slot in as a B1 who can provide high burst generation
                to the team. This can allow us to use B3 units with low burst
                generation with much more ease, such as Scarlet and Modernia
                together. Flex spot being a healer can help rumani's
                survivability a lot as well.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <h5>Team #1: Typical 2 RL nuke</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="miracle-fairy-anne"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rumani" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This team is nothing new. We just replaced Anis (SR Version)
                with Rumani which is SSR. With the same investment, any SSR will
                have 20% more stat than the SR version in the same role. And at
                least Rumani can bring “something” to the table instead of Anis.
              </p>
              <h5>Team #2: Electric team goes Zzzttt</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="moran" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="pepper" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="biscuit" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sparkling-summer-anis"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rumani" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This team consists of 3 Electric units which are Moran, Biscuit
                and S.Anis. Sanis will heavily buff the Electric unit which
                benefits from Biscuit too. And you have Moran in P1 to protect
                against any funny SBS. Moran’s damage reduction helps against
                nukes too, and once Sanis has lifesteal, she should be able to
                tank a lot. Very annoying team, and Moran is better than Noise
                here because she’s Defender.
              </p>
              <h5>Team #3: Can it go faster?</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noah" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="quency-escape-queen"
                      enablePopover
                    />{' '}
                    <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rumani" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                This one is very tricky - the more quantum Cubes you put on
                those batteries (Noah + Jackal + Rumani), the faster it can be,
                up to 2.4 RL. It has an insane ATK buff from Red Hood, insane
                protection from Noah, and the sweep from QEQ/Scarlet. Pretty
                scary one to deal with.
              </p>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Super high burst generation.</li>
                        <li>Quite tanky on her own.</li>
                        <li>Skills have an okayish base value.</li>
                        <li>
                          Girl has no chill, and actively tries to seduce the
                          commander.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Kit is so weak on the PvE side. Very little offensive
                          buffs.
                        </li>
                        <li>
                          Scaling is also terrible; you don’t want to invest
                          anything on her skills besides CP padding.
                        </li>
                        <li>
                          PVP is not liked by most players although it is the
                          most common stream of gems. Hence she is a skippable
                          unit for most.
                        </li>
                        <li>
                          Anniversary is super super close, and can be
                          considered a bait due to her PvP prowess.
                        </li>
                        <li>
                          All that muscle, but very little to show for it
                          against raptures (PvE Content).
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesRumani;

export const Head: React.FC = () => (
  <Seo
    title="Rumani Guide & Review | NIKKE | Prydwen Institute"
    description="A guide & review for Rumani in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
